import { proximaNova } from '@/utils/fonts'
import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { SwiperSlide, Swiper } from 'swiper/react'

export const Root = styled.section<{ shouldShowVideo: boolean }>`
  position: relative;
  display: flex;
  min-height: ${({ shouldShowVideo }) => (shouldShowVideo ? '465px' : undefined)};
  -webkit-font-smoothing: antialiased; // text smoothing
  background: #1f375b;
  overflow: hidden;
`

export const VideoContainer = styled.div<{ shouldShowVideo: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%); // center video on resolutions over 1920px
  z-index: 10;
  width: 100vw;
  max-width: 1920px;
  height: 465px;
  opacity: ${({ shouldShowVideo }) => (shouldShowVideo ? 1 : 0)};
  pointer-events: ${({ shouldShowVideo }) =>
    shouldShowVideo ? 'auto' : 'none'}; // allow button clicks during video exit transition
  transition: opacity 0.2s;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  max-height: 465px;
  object-fit: cover;
`

export const SkipButton = styled.button`
  position: absolute;
  right: 24px;
  bottom: 4px;
  z-index: 10;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  font-size: 16px;
  letter-spacing: 0.2px;
  color: white;
  background: none;
  border: none;
  padding: 8px;

  img {
    margin-bottom: 1px;
  }
`

export const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100vw;
  max-width: 1920px;
  min-height: 300px;
  max-height: 465px;

  ${mediaEmotion.sm(css`
    height: 465px;
  `)}

  .swiper-button-prev,
  .swiper-button-next {
    color: #1493ff44;
    transition: color 0.2s;

    &:hover {
      color: #1493ff;
    }
  }

  .swiper-button-prev {
    left: 24px;
  }

  .swiper-button-next {
    right: 24px;
  }
`

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`

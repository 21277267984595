import { graphql } from '../../gql/wrapper/gql'

// Used to fetch CMS rewrite page slugs
export const getRewrites = graphql(`
  query getRewrites {
    allMkRewrite(where: { _: { is_draft: false } }) {
      mk_old_path
      _updatedAt
    }
  }
`)

export default getRewrites

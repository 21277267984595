import { useCallback } from 'react'
import styles from './ResponsiveBanner.module.scss'
import { useAmplitude } from '@/utils/hooks/useAmplitude'

type ResponsiveBannerProps = {
  desktopImage: string
  tabletImage: string
  mobileImage: string
  altText: string
  ctaLink: string
  amplitudeEventName?: string
  amplitudeEventProps?: {
    Module?: string
    Placement?: string
    'Link Text'?: string
    Product?: string
  }
}

const ResponsiveBanner = ({
  desktopImage,
  tabletImage,
  mobileImage,
  altText,
  ctaLink,
  amplitudeEventName,
  amplitudeEventProps,
}: ResponsiveBannerProps) => {
  const { logAmplitudeEvent } = useAmplitude()

  const handleCTAClick = useCallback(() => {
    if (amplitudeEventName) {
      logAmplitudeEvent(amplitudeEventName, ctaLink, {
        Module: amplitudeEventProps?.Module,
        Placement: amplitudeEventProps?.Placement,
        'Link Text': amplitudeEventProps?.['Link Text'] ?? altText,
        Product: amplitudeEventProps?.Product,
      })
    }
  }, [amplitudeEventName, amplitudeEventProps, ctaLink, altText, logAmplitudeEvent])

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        <a href={ctaLink} className={styles.ctaLink} onClick={handleCTAClick}>
          <picture>
            <source media="(min-width: 1920px)" srcSet={desktopImage} />
            <source media="(min-width: 1440px)" srcSet={desktopImage} />
            <source media="(min-width: 1024px)" srcSet={desktopImage} />
            <source media="(min-width: 960px)" srcSet={tabletImage} />
            <source media="(min-width: 640px)" srcSet={tabletImage} />
            <img src={mobileImage} alt={altText} style={{ width: '100%', height: 'auto' }} />
          </picture>
        </a>
      </div>
    </div>
  )
}

export default ResponsiveBanner

import {
  MkCustomHtmlTemplate,
  MkCustomJsTemplate,
  MkHeading,
  MkInfoCardsTemplate,
  MkWhereCanYouPlayTemplate,
  MkWhyFanDuelTemplate,
} from 'graphql/gql/wrapper/graphql'
import CustomHTML from './HTML/CustomHTML'
import CustomJS from './JS/CustomJS'
import Cards from './Cards/Cards'
import Heading from './Heading/Heading'
import CarouselHero from './CarouselHero/CarouselHero'
import Spacer from './Spacer/Spacer'
import WhereCanYouPlay from './WhereCanYouPlay/WhereCanYouPlay'
import WhyFanDuel from './Cards/InfoCards/WhyFanDuel'
import ResponsiveBanner from './Banner/ResponsiveBanner'

type PageSectionProps = {
  details: any | null
}

export default function PageSection({ details }: Readonly<PageSectionProps>) {
  if (details?._type === 'mk_infoCardsTemplate') {
    return <Cards mk_infoCards={details as MkInfoCardsTemplate} />
  }
  if (details?._type === 'mk_heading') {
    return <Heading mk_heading={details as MkHeading} />
  }

  if (details?._type === 'mk_spacer') {
    return <Spacer {...details} />
  }

  if (details?._type === 'mk_carouselHero') {
    return <CarouselHero {...details} />
  }

  if (details?._type === 'mk_customHtmlTemplate') {
    return <CustomHTML mk_customHtml={details as MkCustomHtmlTemplate} />
  }

  if (details?._type === 'mk_customJsTemplate') {
    return <CustomJS mk_customJs={details as MkCustomJsTemplate} />
  }

  if (details?._type === 'mk_whereCanYouPlayTemplate') {
    return <WhereCanYouPlay mk_whereCanYouPlay={details as MkWhereCanYouPlayTemplate} />
  }

  if (details?._type === 'mk_whyFanDuelTemplate') {
    return <WhyFanDuel mk_whyFanDuel={details as MkWhyFanDuelTemplate} />
  }

  // Check for mk_responsiveBanner and render ResponsiveBanner
  if (details?._type === 'mk_responsiveBanner') {
    details as MkWhyFanDuelTemplate
    return (
      <ResponsiveBanner
        desktopImage={details.desktopImage?.asset?.url}
        tabletImage={details.tabletImage?.asset?.url}
        mobileImage={details.mobileImage?.asset?.url}
        altText={details.altText}
        ctaLink={details.ctaLink}
        amplitudeEventName={details.moduleAnalyticsEventProperty}
        amplitudeEventProps={{
          Module: details.moduleAnalyticsEventProperty,
          Placement: details.placementAnalyticsEventProperty,
          'Link Text': details.altText,
        }}
      />
    )
  }

  return <></>
}

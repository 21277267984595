import { graphql } from '../../gql/wrapper/gql'

export const getHomepageCmsAttributes = graphql(`
  query getHomepageCmsAttributes {
    allMkHomePage(where: { _: { is_draft: false } }) {
      _updatedAt
    }
  }
`)

export default getHomepageCmsAttributes

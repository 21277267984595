import { graphql } from '../../gql/wrapper/gql'

// Used to fetch CMS marketing page slugs
export const getPages = graphql(`
  query getPages {
    allMkMarketingPage(where: { _: { is_draft: false } }) {
      _id
      mk_slug {
        current
      }
      _updatedAt
    }
  }
`)

export default getPages

import { useEffect, useRef, useState } from 'react'
import { SwiperRef } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { A11y, Autoplay, Keyboard, Navigation } from 'swiper/modules'
import useWindow from '@/utils/hooks/useWindow'
import { Maybe, MkCarouselHero } from 'graphql/gql/wrapper/graphql'
import CarouselHeroSlide from './CarouselHeroSlide'
import Image from 'next/image'
import { Root, VideoContainer, Video, SkipButton, StyledSwiper, StyledSwiperSlide } from './CarouselHero.styled'

export enum MobileSlideNavUi {
  BOTTOM_NAV = 'bottomNav',
  NONE = 'none',
}

export enum DesktopSlideNavUi {
  BOTTOM_NAV = 'bottomNav',
  SIDE_NAV = 'sideNav',
  NONE = 'none',
}

export type CarouselHeroProps = MkCarouselHero

const CarouselHero = ({
  mobileIntroVideoUrl,
  desktopIntroVideoUrl,
  mobileSlideNavUi,
  desktopSlideNavUi,
  slides,
  moduleAnalyticsEventProperty,
  placementAnalyticsEventProperty,
}: CarouselHeroProps) => {
  const [shouldShowVideo, setShouldShowVideo] = useState(!!(mobileIntroVideoUrl || desktopIntroVideoUrl))
  const [shouldHaveVideoInDom, setShouldHaveVideoInDom] = useState(!!(mobileIntroVideoUrl || desktopIntroVideoUrl))
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const windowData = useWindow()
  const { isMobile } = windowData

  const carouselRef = useRef<SwiperRef>(null)

  useEffect(() => {
    getVideoUrl() && shouldShowVideo
      ? carouselRef.current?.swiper?.autoplay?.stop()
      : carouselRef.current?.swiper?.autoplay?.start()
  }, [shouldShowVideo])

  // Will not render CarouselHero if all slides are hidden
  const numSlides = slides?.filter((slide) => !slide?.isHidden)?.length ?? 0

  const getVideoUrl = () => {
    if (mobileIntroVideoUrl && isMobile) return mobileIntroVideoUrl
    return desktopIntroVideoUrl ?? ''
  }

  return (
    <>
      {numSlides > 0 && (
        <Root shouldShowVideo={shouldShowVideo} data-testid="carousel-hero">
          {getVideoUrl() && shouldHaveVideoInDom && (
            <VideoContainer
              shouldShowVideo={shouldShowVideo}
              onTransitionEnd={() => {
                setShouldHaveVideoInDom(false)
              }}
              data-testid="video-container"
            >
              <Video
                key={isMobile ? 'mobileVideo' : 'desktopVideo'} // Reloads video on resize past 640
                autoPlay
                muted
                playsInline // Ensures video plays on iOS devices
                onEnded={() => setShouldShowVideo(false)}
                onError={(error) => {
                  console.error('Video load error', error)
                  setShouldShowVideo(false)
                  setShouldHaveVideoInDom(false)
                }}
                data-testid="video"
              >
                <source src={getVideoUrl()} />
              </Video>
              <SkipButton onClick={() => setShouldShowVideo(false)} aria-label="Skip Video">
                Skip
                <Image
                  src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/next-arrow.svg"
                  alt="Next Slide Arrow"
                  width={18}
                  height={18}
                />
              </SkipButton>
            </VideoContainer>
          )}

          <StyledSwiper
            style={{
              visibility: getVideoUrl() && shouldShowVideo ? 'hidden' : 'visible',
              // If one slide, height is responsive to content. If more, height is fixed at 465px
              height: numSlides > 1 ? '465px' : undefined,
            }}
            ref={carouselRef}
            navigation={!isMobile && numSlides > 1 && desktopSlideNavUi === DesktopSlideNavUi.SIDE_NAV}
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            keyboard={{ enabled: true }}
            a11y={{
              prevSlideMessage: 'Previous slide',
              nextSlideMessage: 'Next slide',
            }}
            modules={[Navigation, Autoplay, Keyboard, A11y]}
            loop
            onSlideChange={() => setActiveSlideIndex(carouselRef.current?.swiper.realIndex ?? 0)}
          >
            {slides?.map((slide, index) => {
              return (
                !slide?.isHidden && (
                  <StyledSwiperSlide key={slide?.title ?? index}>
                    <CarouselHeroSlide
                      windowData={windowData}
                      carouselRef={carouselRef}
                      slideIndex={index}
                      activeSlideIndex={activeSlideIndex}
                      numSlides={numSlides}
                      mobileSlideNavUi={mobileSlideNavUi as Maybe<MobileSlideNavUi> | undefined}
                      desktopSlideNavUi={desktopSlideNavUi as Maybe<DesktopSlideNavUi> | undefined}
                      moduleAnalyticsEventProperty={moduleAnalyticsEventProperty}
                      placementAnalyticsEventProperty={placementAnalyticsEventProperty}
                      {...slide}
                    />
                  </StyledSwiperSlide>
                )
              )
            })}
          </StyledSwiper>
        </Root>
      )}
    </>
  )
}

export default CarouselHero
